<template>
  <div class="nav">
    <div class="nav-wrapper">
      <div class="nav-inner">
        <a class="nav-logo">
          <img :src="webp(require(`../../../public/img/detail/${page}/logo.png`))" :alt="`logo ${page}`">
        </a>
        <div class="nav-btn">
          <div class="nav-btn__inner" :class="{active: menuOpen}" @click="menuOpen = !menuOpen">
            <span></span><span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-content" :class="{open: menuOpen}">
      <div class="nav-content__wrapper">
        <div class="nav-content__inner">
          <div class="nav-content__list">
            <div class="nav-content__item fadeup"><a href="/" class="nav-content__link">{{ $t(`navigation.home`) }}</a></div>
            <div class="nav-content__item fadeup"><a href="/corporate/" class="nav-content__link" :class="{active: page==='coporate'}">{{ $t(`navigation.corporate`) }}</a></div>
            <div class="nav-content__item fadeup"><a href="/product/karamucho" class="nav-content__link" :class="{active: page==='karamucho'}">KARAMUCHO</a></div>
            <div class="nav-content__item fadeup"><a href="/product/koimucho" class="nav-content__link" :class="{active: page==='koimucho'}">KoiMUCHO</a></div>
            <div class="nav-content__logo fadeup">
              <a href="/product/karamucho" class="nav-content__logo__karamocho">
                <img :src="webp(require(`../../../public/img/menu-logo-karamucho.png`))" alt="karamucho">
              </a>
              <a href="/product/koimucho" class="nav-content__logo__koimocho">
                <img :src="webp(require(`../../../public/img/menu-logo-koimucho.png`))" alt="koimucho">
              </a>
            </div>
            <div class="nav-content__social fadeup">
              <a href="https://www.facebook.com/Karamucho.Vn/" class="nav-content__social__item" target="_blank" rel="noopener noreferrer">
                <img :src="webp(require(`../../../public/img/icon/icon-facebook.png`))" alt="karamucho">
              </a>
              <a href="https://www.youtube.com/channel/UCUNnWTLL2CyNMmF2dpbd9xg" class="nav-content__social__item" target="_blank" rel="noopener noreferrer">
                <img :src="webp(require(`../../../public/img/icon/icon-youtube.png`))" alt="koikeya youtube">
              </a>
              <a href="javascript:void(0)" class="nav-content__social__item language" @click="changeLocale(switchedLocale)">{{ switchedLocale.toUpperCase() }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    page: String,
    locale: String
  },
  data() {
    return {
      menuOpen: false,
      screenWidth: 0
    }
  },
  watch: {
    menuOpen: function() {
      let html = document.querySelector('html');
      if (this.menuOpen) {
        html.classList.add('menu-open')
      } else {
        html.classList.remove('menu-open')
      }
    }
  },
  computed: {
    switchedLocale() {
      return this.locale === 'vi' ? 'en' : 'vi'
    }
  },
  mounted() {
    this.screenWidth = window.outerWidth;
    //this.deviceWidth();
    //window.addEventListener('scroll', this.navScroll())
  },
  methods: {
    navScroll: function() {
      let el = document.querySelector('.nav-btn'),
          pos = el.getBoundingClientRect().top;
      //console.log(pos)
      if (pos <= 20 ) {
        el.classList.add('fixed')
      } else  {
        el.classList.remove('fixed')
      }  
    },
    deviceWidth() {
      if (this.screenWidth <= 1024) {
        document.querySelector('html, .nav').style.width = this.screenWidth + 'px'
      }
    },
    changeLocale(locale) {
      this.$emit('changeLocale', locale)
      this.menuOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/mixin/_mixin';

  .nav {
    position: absolute;
    top: 3rem;
    left: 0;
    width: 100%;
    z-index: 99;

    @include media(phone) {
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      box-shadow: 0 3px 15px rgba(0,0,0,.2);
    }
    &-wrapper {
      padding-left: 10rem;
      padding-right: 6.5rem;

      @include media(set-max-w, 1366) {
        padding-left: 3rem;
        padding-right: 2rem;
      }
      @include media(tablet) {
        padding: 0 2rem
      }
      @include media(phone) {
        padding: 0 1.4rem
      }
    }
    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media(phone) {
        height: 7rem;
      }
    }
    &-logo {
      display: block;

      @include media(tablet) {
        width: 13rem;
      }
      @include media(phone) {
        width: 7.5rem;
      }
    }
    &-content {
      overflow: hidden;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 2px;
      height: 0;
      background: #000000;
      z-index: -1;
      transition: width .3s cubic-bezier(0.165, 0.84, 0.44, 1), height .3s .3s cubic-bezier(0.165, 0.84, 0.44, 1);

      &.open {
        transition: width .4s .3s cubic-bezier(0.165, 0.84, 0.44, 1), height .3s cubic-bezier(0.165, 0.84, 0.44, 1);
        width: 101%;
        height: 101%;
        z-index: 9;

        .fadeup {
          opacity: 1;
          transform: translateY(0);
          transition: .4s ease;

          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              transition-delay: (0.1s * $i) + .6s
            }
          }
        }
      }
      &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        //width: 600px;
        max-width: 100%;
        padding: 0 2rem;
        margin: 0 auto;
      }
      &__inner {
        text-align: center;
      }
      &__item {
        padding: .5rem 0;
        opacity: 0;
        transform: translateY(30px);
        
      }
      &__link {
        position: relative;
        font-size: 5.6rem;
        font-weight: 400;
        color: #6d6d6d;
        letter-spacing: 2px;
        text-transform: capitalize;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          width: 0;
          transition: .4s ease-in-out;
          background: #ffffff;
        }
        &:hover {
          &:after {
            width: 100%;

            @include media(phone) {
              display: none
            }
          }
        }

        @include media(phone) {
          font-size: 3rem;
        }
        &.active {
          color: #fff;
          font-weight: 700;
        }
      }
      &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem -3.5rem 0;
        opacity: 0;
        transform: translateY(30px);

        &__karamocho,
        &__koimocho {
          width: 50%;
          padding: 0 3.5rem;

          @include media(phone) {
            width: 40%;
          }
        }
      }
      &__social {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        opacity: 0;
        transform: translateY(30px);

        &__item {
          padding: 0 4.5rem;

          @include media(phone) {
            padding: 0 3rem 
          }
        }
        .language {
          color: #cacaca;
          font-size: 2rem;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
    }
    &-btn {
      position: fixed;
      right: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6rem;
      height: 6rem;
      background: #ffffff;
      box-shadow: -4px 4px 12px 4px rgba(#000, .2);
      z-index: 10;

      &.fixed {
        position: fixed;
        top: 20px;
        right: 6.5rem;
      }
      @include media(set-max-w, 1366) {
        right: 2rem;
      }
      @include media(tablet) {
        right: 2rem
      }
      @include media(phone) {
        right: 1.4rem;
        background: none;
        box-shadow: none;
      }
      &__inner {
        width: 4.4rem;
        height: 4.4rem;
        position: relative;
        cursor: pointer;

        &:hover {
          span {
            &:last-child {
              width: 100%;

              @include media(phone) {
                width: 60%;
              }
            }
          }
        }
        &.active {
          span {
            @include media(phone) {
              background: #fff;
            }
            transition: .4s .4s cubic-bezier(0.165, 0.84, 0.44, 1), width .4s cubic-bezier(0.165, 0.84, 0.44, 1);
            &:first-child {
              top: 22px;
              transform: rotate(45deg)
            }
            &:last-child {
              top: 22px;
              width: 100%;
              transform: rotate(135deg);
            }
          }
        }
        span {
          position: absolute;
          right: 0;
          height: 3px;
          width: 100%;
          background: #000000;
          transition: .4s cubic-bezier(0.165, 0.84, 0.44, 1), width .4s .4s cubic-bezier(0.165, 0.84, 0.44, 1);

          &:first-child {
            top: 18px
          }
          &:last-child {
            top: 26px;
            width: 60%;
          }
        }
      }
    }
  }
</style>